@layer components {

  /* define Custom fonts */
  /* headline1 */

  .bk-headline1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.015em;
  }

  /* headline2 */
  .bk-headline2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }

  /* headline3 */
  .bk-headline3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
  }

  /* sub-headline1 */
  .bk-sub-heading1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  /* sub-headline2 */
  .bk-sub-heading2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  /* sub-headline3 */
  .bk-sub-heading3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  /* button-large typography */
  .bk-btn-large {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  /* button-small typography*/
  .bK-btn-small {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  /* bk-hint-text */
  .bK-hint-text {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 0 !important;
  }

  /* bk-body1 */
  .bK-body1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  /* btn-body2 */
  .bK-body2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  /* btn-body3 */
  .bK-body3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
  }

  /* Main Section Style */
  .bk-main-section {
    height: calc(100vh - 112px);
    @apply px-6 py-8 w-full overflow-auto;
  }

  .bk-main-instruction-section {
    height: calc(100vh - 140px);
    @apply px-6 py-8 w-full overflow-auto;
  }

  .bk-main-view-section {
    height: calc(100vh - 60px);
    @apply py-8 w-full overflow-auto;
  }

  .bk-main-stepper-section {
    height: calc(100vh - 200px);
    @apply px-6 py-6 w-full overflow-auto;
  }

  .bk-main-confirm-section {
    height: calc(100vh - 112px);
    @apply pt-1 pb-8 w-full overflow-auto;
  }

  /* Button */
  .btn-primary {
    @apply bg-bk_action_primary rounded-lg outline-none border-none cursor-pointer bK-btn-small;
  }

  .btn-primary:focus {
    @apply outline-none;
  }

  .btn-secondary {
    @apply bg-bk_stroke_secondary rounded-lg outline-none border-none cursor-pointer bK-btn-small;
  }

  .btn-secondary:focus {
    @apply outline-none;
  }

  /* Dashboard Menu Card Icon Background */
  .dashboard-icon-background {
    width: 34px;
    height: 34px;
    @apply bg-bk_tag_blue_bg rounded-full flex justify-center items-center object-contain;
  }

  /* Success Gif rounded container */
  .success-gif-container {
    width: 130px;
    height: 130px;
    top: -30%;
    @apply absolute z-50 rounded-full flex justify-center items-center object-contain bg-bk_bg_primary;
  }

  .eSign-box {
    border: 1px solid #BDBDBD;
    width: 350px;
    height: 200px;
    border-radius: 8px;
    @apply flex flex-row-reverse;
  }

  .eSign-clear-btn {
    color: #6F35F2;
    background-color: inherit !important;
    height: fit-content;
    @apply btn-secondary mt-2 mr-4 underline;
  }

  .consent-btn {
    background-color: #FFFFFF !important;
    border: 1px solid #6F35F2 !important;
    color: #6F35F2 !important;
    font-size: 16px !important;
    @apply py-2 px-4 mt-2 rounded-lg btn-primary cursor-pointer flex items-center w-full justify-center;
  }

  .consent-file-upload {
    min-height: 68px;
    @apply w-full h-auto;
  }


  .bk-stepper-scroll {
    max-width: 525px;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    scrollbar-width: none;
  }

  .bk-stepper-side-blur {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none;
  }

  .bk-dc-stepper-section {
    width: '100%';
    position: 'sticky';
    top: 3;
    z-index: 999
  }

  .completed-step-icon {
    width: 100%;

    &.MuiStepIcon-root.Mui-completed {
      color: var(#048218) !important;
    }
  }

  .css-18ibvgg-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    width: 100%;
  }

  .css-1vyamtt-MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel {
    text-align: center;
    flex-wrap: wrap;
    display: flex;
    text-wrap: pretty;
  }
}